<template>
  <div>
    <el-card shadow="never">
      <MoTable
        :cols="cols"
        :tableData="tableData"
        @handleEventButton="handleEventButton"
        :page="page"
      />
    </el-card>
  </div>
</template>

<script>
import MoTable from "../../../components/MoTable/MoTable";
export default {
  components: {
    MoTable,
  },
  data() {
    return {
      tableData: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      cols: [
        { label: "用户名称", prop: "user" },
        { label: "操作", prop: "content" },
        { label: "操作时间", prop: "time" },
      ],
    };
  },
  created() {
    this.getLogs();
  },
  methods: {
    async getLogs() {
      const res = await this.$get("/user/getLogs",this.page);
      console.log(res);
      if (res.flag) {
        this.tableData = res.data.rows;
        this.page.total = res.data.total
      }
    },
    handleEventButton(type, row) {
        this.page.pageNum = row;
        this.getLogs();
    },
  },
};
</script>

<style>
</style>